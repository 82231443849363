import '../style/less/new/index.less'

import $ from 'jquery'
import { tns } from 'tiny-slider/src/tiny-slider.module'

if($('.slider').length!=0){
    let slider = tns({
        container: '.slider',
        autoplay: true,
        items: 1,
        controls: false,
        autoplayButtonOutput: false,
        speed: 500,
    })
    slider.events.on('indexChanged', info => {
    })
}
if($('.slider2').length!=0){
    let slider2 = tns({
        container: '.slider2',
        autoplay: true,
        items: 1,
        controls: false,
        autoplayButtonOutput: false,
        speed: 500,
        nav: false
    })
    slider2.events.on('indexChanged', info => {
    })
}
if($('.slider3').length!=0){
    let slider3 = tns({
        container: '.slider3',
        autoplay: true,
        items: 1,
        controls: false,
        autoplayButtonOutput: false,
        speed: 500,
        nav: false
    })
    slider3.events.on('indexChanged', info => {
    })
}
if($('.slider4').length!=0){
    let slider4 = tns({
        container: '.slider4',
        autoplay: true,
        items: 1,
        controls: false,
        autoplayButtonOutput: false,
        speed: 500,
        nav: false
    })
    slider4.events.on('indexChanged', info => {
    })
}
if($('.slider5').length!=0){
    let slider5 = tns({
        container: '.slider5',
        autoplay: true,
        items: 1,
        controls: false,
        autoplayButtonOutput: false,
        speed: 500,
        nav: false
    })
    slider5.events.on('indexChanged', info => {
    })
}

//展览中心详情页
window.onload=function(){
    getUserLoginState()
    getEvaluateStand()
}
function getUserLoginState(){
    $.ajax({
        url: "/api/common/login/status?t="+new Date().getTime(),		//请求地址
        xhrFields: {withCredentials: true},
        crossDomain: true,
        type : "GET",
        dataType : 'json',
        contentType:"application/x-www-form-urlencoded;charset=UTF-8",
        success : function(data) {
            if (data.status.code== 1) {
                $('.lore').addClass("none")
                $('#register_li').css('display',"none")
                $('#username').html(data.result)
                $('.haslore').removeClass("none")
            }
        }
    });
}
function getEvaluateStand(){
    var location = window.location.href
    var index1 = location.indexOf("service/detail")
    var index2 = location.indexOf("require/detail")
    var index = location .lastIndexOf("\/");
    var id = location .substring(index + 1, location .length);
    var classfy
    if(index1>=0)
        classfy = "provider"
    else if(index2>=0){
        classfy = "require"
    }
    if(index1>=0||index2>=0){
        $.ajax({
            url: "/getEvaluate?serviceClassify="+classfy+"&serviceId="+id+"&t="+new Date().getTime(),		//请求地址
            type : "GET",
            dataType : 'json',
            xhrFields: {withCredentials: true},
            crossDomain: true,
            contentType:"application/x-www-form-urlencoded;charset=UTF-8",
            success : (data)=> {
                if(data.status.code===1){
                    $('#dealnum').html(data.result.volume)
                    if(classfy==="require")
                        $.each(data.result.evaluateScore,function(index,item){
                            $('.rankitem').eq(index).find('span').html(item.evaluateName)
                            for(var i=Math.round(item.scoreFloat);i<5;i++){
                                var src=$('.rankitem').eq(index).find('div').find('img').eq(i).attr('src')
                                $('.rankitem').eq(index).find('div').find('img').eq(i).attr('src',src.replace("star_1","star_2"))
                            }
                        })
                    else  $.each(data.result.evaluateScore,function(index,item){
                        $('.rankitem').eq(index).find('span').html(item.evaluateName)
                        for(var i=Math.round(item.scoreFloat);i<5;i++){
                            var src=$('.rankitem').eq(index).find('div').find('img').eq(i).attr('src')
                            $('.rankitem').eq(index).find('div').find('img').eq(i).attr('src',src.replace("star_1","star_2"))
                        }
                    })

                }
            }
        });
        var template = "<div class='commentitem'><div class='fromusername'>{{userName}}</div>： <div class='commenttext'>{{comment}}</div></div>";
        Mustache.parse(template);   // optional, speeds up future uses
        var template2="  <div class=\"commentitem\">\n" +
            "                <div class=\"comment-left\">\n" +
            "                    <img class=\"comment-head-img\" src=\"http://pavb1z1iv.bkt.clouddn.com/{{headImg}}\"/>\n" +
            "                </div>\n" +
            "                <div class=\"comment-right\">\n" +
            "                    <div class=\"first-line\">\n" +
            "                        <span class=\"comment-username\">{{userName}}</span>\n" +
            "                        <span class=\"comment-date\">2017-9-10</span>\n" +
            "                    </div>\n" +
            "                    <div class=\"second-line\">\n" +
            "                        <span class=\"second-line-comment\">评价：</span>\n" +
            "                        <span class=\"comment\">{{comment}}</span>\n" +
            "                    </div>\n" +
            "                </div>\n" +
            "            </div>"
        $.ajax({
            url: "/getComment?serviceClassify="+classfy+"&serviceId="+id+"&t="+new Date().getTime(),		//请求地址
            type : "GET",
            dataType : 'json',
            xhrFields: {withCredentials: true},
            crossDomain: true,
            contentType:"application/x-www-form-urlencoded;charset=UTF-8",
            success : (data)=> {
                if(data.status.code===1){
                    if(classfy==="require"){
                        $('.comments').html('')
                        $.each(data.result.comments,function(index,item){
                            var eachComment=Mustache.render(template,item)
                            $('.comments').append(eachComment)
                        })
                    }
                    else{
                        $('.comments').html('')
                        $.each(data.result.comments,function(index,item){
                            var eachComment=Mustache.render(template2,item)
                            $('.comments').append(eachComment)
                        })
                    }
                }
            }
        });
    }
}

$('#login_li').on('click',function(){
    window.location.href="/sign/login?from="+window.location.href
})
$('#register_li').on('click',function(){
    window.location.href="/sign/register?from="+window.location.href
})
$("#index-click").on('click',function () {
    var top=($("#index").offset().top)|0;
    console.log(top);
    $("html,body").animate({scrollTop:top+"px"},"slow");
})

$("#case-click").on('click',function () {
    var top=($("#case").offset().top)|0;
    console.log(top);
    $("html,body").animate({scrollTop:top+"px"},"slow");
})

var isShow = false;
$(".navbar-toggle").click(function () {
    isShow = !isShow;
    if(isShow){
        $("#navbar-collapse").css("display","block");
    }else{
        $("#navbar-collapse").css("display","none");
    }
})